/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'swiper/css/bundle';

@import '~@vime/core/themes/default.css';



// hide scrollbar
::-webkit-scrollbar {
  display: none;
}



/* ! dark mode css start */
.md body[color-theme="dark"] {

  ion-item-divider {
    --background: #252525;
    --color:white;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .commentText {
    --background: rgb(30, 30, 30) !important;
    --border-radius: 10px;
    --box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  ion-header {
    ion-toolbar {
      --background: rgb(0, 0, 0);
    }
  }
  .selectedCat {
    box-shadow: none;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #20359e 0%, #a5219c 46%, #dda338 100%);
    color: white;
  }
  ion-content {
    --background: rgb(0, 0, 0);

    p {
      color: #eee !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    b {
      color: #eee !important;
    }

    ion-label {
      color: #eee !important;
    }

    span {
      color: #eee !important;
    }

    ion-chip {
      color: #eee !important;
      background: black;
    }

    .greeting {
      color: #eee !important;
      background-color: black !important;
      background-image: none;
    }

    .selectedCat {
      box-shadow: none;
      background-color: #4158D0;
      background-image: linear-gradient(43deg, #20359e 0%, #a5219c 46%, #dda338 100%);
      color: white;
    }

    .form {
      background: #000000;
      color: #eee;
    }

    .week {
      background-color: #121212;
    }

    .selectedDay {
      border-color: black;
      background-color: #4158D0;
      background-image: linear-gradient(43deg, #20359e 0%, #a5219c 46%, #dda338 100%);

    }

    .wrapper .row section {
      background: #121212 !important;
    }

    .wrapper .row section::before {
      background: #121212 !important;
    }

    .row section .icon,
    .center-line .scroll-icon {
      color: #eee !important;
      background: #121212 !important;
      box-shadow: 0 0 0 4px rgb(43, 43, 43), inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%) !important;
    }
  }



  .selected {
    ion-icon {
      color: #eee !important;
    }

    ion-label {
      color: #eee !important;
    }
  }

  .supportText {
    background-color: black !important;
    background-image: none;
    color: #eee !important;
  }

  .author {
    ion-avatar {
      background-color: #eee;
    }

    h2 {
      color: #eee !important;
    }
  }

  .post {
    .content {
      .title {
        color: #eee !important;
      }

      .time {
        color: rgb(198, 198, 198) !important;
      }

      .desc {
        color: #eee !important;
      }
    }

    p {
      color: #eee !important;
    }
  }
}



/* ! dark mode css end */







ion-content {
  // --padding-top:30px;
 // --background: rgb(250, 250, 250);
}


ion-header {
  ion-toolbar {
    //--background: rgb(250, 250, 250);


    ion-title {
      //   text-align: center;
    }
  }
}

ion-button {
  //   --border-radius: 10px;
  // --box-shadow: none;
  text-transform: none;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

ion-item {
  // border-radius: 5px;
}


.viewProfileModal {
  /*       --height:40%;
      --width:80%; */
  --border-radius: 10px;
  //  backdrop-filter: blur(1px);
  --box-shadow: 0 5px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  //  background-color: #4158D0;
  //  background: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  --backdrop-opacity: 0.4;

}

.mediaSelectionModal {
 // --ion-background-color: #ffffff00;
 --background: transparent;
  --height: 30%;
  --width: 90%;
  --border-radius: 10px;
  --backdrop-opacity: 0.1;
  backdrop-filter: blur(10px);
  .modal-wrapper{
    background: transparent !important;
}
}




.videoModal {

  --height: 100%;
  --width: 100%;
  // --border-radius: 10px;
  --backdrop-opacity: 0.4;
  --background: rgb(0, 0, 0);

  ion-content {
    --background: rgba(255, 255, 255, 0);
  }
}

.swiper-pagination-bullet-active {
  height: 7px !important;
  width: 7px !important;
  vertical-align: baseline !important;
  background-color: red !important;
}


.swiper-pagination-bullet {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.82);
  height: 7px;
  width: 7px;
}


ion-toast.likedToast{
  --background: white;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #4b4a50;
}

ion-toast.likedToast::part(header) {
  font-weight: bold;
  color: black;
}



img{
 /*  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none; */
 // pointer-events: none;
 -webkit-user-drag: none;
  -webkit-user-select: none;
}